import React, {FC} from 'react'
import {Helmet} from 'react-helmet'

const ZHelmet: FC<{
  title?: string
  description?: string
  image?: string
  keywords?: string
  ogUrl?: string
}> = props => {
  const title =
    props.title ||
    `A Simplified Instant Loan for Personal,Business,Used Car, 2-Wheelers`
  const description =
    props.description ||
    'We understand your Loan needs. Fulfil your Dreams with our Easy, Instant personal loans, business loans, used car loans, and two-wheeler loans. Apply Now.'
  const image =
    props.image ||
    'https://res.cloudinary.com/dhoiqmk4x/image/upload/c_scale,w_400/v1598946438/Website/Fevicon_White_bg_n3gi6t.png'
  const {keywords, ogUrl = 'https://zavronfinserv.com'} = props
  return (
    <Helmet>
      <meta name="theme-color" content="#001f79" />
      <meta name="description" content={description} />
      {keywords ? <meta name="keywords" content={keywords} /> : null}
      {/* <link
        rel="icon"
        href="https://res.cloudinary.com/onemenu/image/upload/v1595890687/website-v2/favicon_1_pgdyot.png"
      /> */}
      <title>{title}</title>

      {/* Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={ogUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={ogUrl} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />

      {/* Extras */}
      {props.children}
    </Helmet>
  )
}

export {ZHelmet}
