import {Link} from 'gatsby'
import React, {FC} from 'react'
import {Box, Text, Stack, Frame} from '../common'
import {BlogItem, BlogDate} from '../pages/blogs'

export const BlogTile: FC<{blog: BlogItem}> = props => {
  const {blog} = props
  return (
    <Box>
      <Link to={`/blog/${blog.slug}`}>
        <Stack space="var(--s-1)">
          <Frame numerator={1} denominator={2}>
            <img
              style={{borderRadius: 5}}
              src={blog.previewImage.file.url}
              alt={blog.postTitle}
            />
          </Frame>
          <BlogDate
            createdAt={blog.createdAt}
            readingTimeInMins={blog.readingTimeInMins}
          />
          <Text style={{lineHeight: '120%'}} variant="h4">
            {blog.postTitle}
          </Text>
        </Stack>
      </Link>
    </Box>
  )
}
