import React, {FC} from 'react'
import {graphql, Link} from 'gatsby'
import {Box, Text, Stack, Switcher, Frame, Center, Grid} from '../common'
import {Layout} from '../components/Layout'
import {BlogTile} from '../components/BlogTile'
import {ZHelmet} from '../components/ZHelmet'


export type BlogItem = {
  postTitle: string
  createdAt: string
  postSummary: string
  previewImage: {
    file: {
      url: string
    }
  }
  readingTimeInMins: string
  slug: string
}

export default function Blogs({data}) {
  const blogs: BlogItem[] = data.allContentfulBlogs.nodes
  const firstBlog = blogs[0]
  return (
    <Layout>
      <ZHelmet
        title="Best Blogs | About Personal Loan | Fintech | NBFC | India"
        description="Read personal loan blogs by Zavron Finserv and follow us regularly to stay updated with new offers on instant personal loan or lower interest rates with easy EMI"
      />
      <Stack space="var(--s4)">
        <Center gutters="var(--s2)" maxWidth="var(--max-width)">
          <Stack space="var(--s4)">
            <Stack space="var(--s2)">
              <Text variant="h2">Blogs</Text>
              <Switcher max={2} threshold="var(--min-width)" space="var(--s2)">
                <Box>
                  <Frame numerator={1} denominator={2}>
                    <img
                      style={{borderRadius: 5}}
                      src={firstBlog.previewImage.file.url}
                      alt={firstBlog.postTitle}
                    />
                  </Frame>
                </Box>
                <Stack space="var(--s-2)" style={{flexGrow: 1.5}}>
                  <BlogDate
                    createdAt={firstBlog.createdAt}
                    readingTimeInMins={firstBlog.readingTimeInMins}
                  />
                  <Link to={`/blog/${firstBlog.slug}`}>
                    <Text style={{lineHeight: '120%'}} variant="h2">
                      {firstBlog.postTitle}
                    </Text>
                  </Link>
                  <Text style={{lineHeight: '120%'}} variant="h4">
                    {firstBlog.postSummary}
                  </Text>
                </Stack>
              </Switcher>
            </Stack>
            <Box>
              <Grid gap="var(--s3)" min="300px">
                {blogs.slice(1, Infinity).map(blog => (
                  <BlogTile blog={blog} />
                ))}
              </Grid>
            </Box>
          </Stack>
        </Center>
      </Stack>
    </Layout>
  )
}

export const BlogDate: FC<{
  createdAt: string
  readingTimeInMins: string
}> = props => (
  <Text variant="label" color="light">
    {props.createdAt}{' '}
    <span
      style={{
        fontSize: 8,
        margin: '0 10px',
      }}>
      &#9679;
    </span>
    {props.readingTimeInMins} min read
  </Text>
)

export const pageQuery = graphql`
  query AllBlogs {
    allContentfulBlogs(
      sort: {fields: createdAt, order: DESC}
      filter: {node_locale: {eq: "en-US"}}
    ) {
      nodes {
        id
        postTitle
        createdAt(formatString: "MMM D, yyyy")
        postSummary
        previewImage {
          file {
            url
          }
        }
        readingTimeInMins
        slug
      }
    }
  }
`
